<template>
    <div>
        <div class="back">
            <div class="tel">
                <div class="upload-box">
                    <div class="upload">
                        <div>
                            <span class="telnumber" style="margin-left: 0;">个人免冠照片：</span>
                            <SingleImage @uploadSuccess="handleSuccess1" :fileList="fileList1"
                                @uploaddelete="uploaddelete" style="margin-top: 20PX;">
                            </SingleImage>
                        </div>
                        <div class="example" style="display: flex;flex-direction: column;">
                            <span>正确示范</span>
                            <img src="../../../assets/image/brand.jpg" alt="" style="height: 140PX;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="briefintroduction">
                <div>请上传本人近期正面、免冠电子证件照（背景需为纯色）。</div>
                <div>请上传符合要求的照片，否则会影响后续实名认证审核。</div>
                <div style="font-size: 12PX;">个人免冠照照片提交后，我们将在1个工作日完成审核，请您耐心等待。</div>
                <div style="font-size: 12PX;">如有技术问题，请拨打以下电话与网站技术联系。</div>
                <div style="font-size: 12PX;">联系电话：010-63827176，咨询时间：工作日（周一至周五）9:00 - 17:00</div>
            </div>
        </div>
        <div class="tel trl">
            <div style="color: red;">*</div>
            <p class="telnumber">照片公示授权：</p>
            <el-radio label="1" v-model="radio2"></el-radio>
            <p style="font-size: 12PX;">我同意北京市体育竞赛管理和国际交流中心使用我所提交的个人免冠照片作为官网裁判员注册信息公示及运动员成绩查询等栏目的展示，以便使用裁判员或运动员相关服务。</p>
        </div>

        <div class="button">
            <div class="save" @click="save">提交审核</div>
        </div>
        <div style="height: 30PX;" v-if="this.$verification(this.$store.state.realnameornot.headPic)"></div>
        <div class="button2" v-if="!this.$verification(this.$store.state.realnameornot.headPic)">
            <div class="save" @click="cancellation">取消</div>
        </div>
    </div>
</template>

<script>
import SingleImage from './component/SingleImage.vue'
export default {
    name: '',
    components: {
        SingleImage
    },
    props: {
        edit: {
            type: Boolean,
            default: false
        },
        distinguish: {
            type: [String, Number],
            default: 0
        }
    },
    data() {
        return {
            radio2: '',
            info: {
                userid: '',
                headPic: '',
            },
            fileList1: [],
        }
    },
    created() {
        if(!this.$verification(this.$store.state.realnameornot.headPic)){
			this.info = {
				headPic: this.$store.state.realnameornot.headPic,
				userid: this.$store.state.userid
			}
			if (this.$store.state.realnameornot.headPic != null) {
				this.fileList1 = [{
					name: this.$store.state.realnameornot.headPic,
					url: this.$store.state.realnameornot.headPic
				}]
			}
		}
        this.userInfo()
        this.$getwxsdk('免冠照审核 -北京市体育竞赛管理和国际交流中心')
    },
    methods: {
        userInfo() {
            let date = {
                phone: this.$store.state.logininformation.phone,
                username: this.$store.state.logininformation.username,
                realname: this.$store.state.logininformation.realname
            }
            this.$api.userInfo(date).then(res => {
                res.data.data.phone = this.$decrypt(res.data.data.phone)
                res.data.data.realname = this.$decrypt(res.data.data.realname)
                res.data.data.username = this.$decrypt(res.data.data.username)
                this.info.phone = res.data.data.phone
            })
        },
        handleSuccess1(e) { // 个人免冠照片
            this.info.headPic = e.message
        },
        uploaddelete(e) {
            if (e == this.info.headPic) {
                this.info.headPic = ''
            }
        },
        save() {

            this.info.userid = this.$store.state.userid

            if (this.$verification(this.info.headPic)) {
                return this.$message.error('请上传个人免冠照片')
            } else if (this.radio2 == 0) {
                return this.$message.error('请勾选照片公示授权')
            }

            this.$api.reSubmitFaceAuth(this.info).then(res => {
                this.$message.success('提交成功')
                this.$store.commit("EXIT_REALN")
                setTimeout(() => {
                    this.$emit('photoautoexamine')
                    this.info = {
                        userid: '',
                        headPic: '',
                    }
                }, 500)
            })

        },
        cancellation() {
            this.$emit('photoautoexamine')
        }
    }
}
</script>

<style lang="scss" scoped>
.back {
    background: #F8FAFF;
    border-radius: 27PX;
    padding: 0 0 20PX 0;
    margin: 20PX 0;
}

.tel {
    display: flex;
    padding-top: 27PX;
    margin-left: 47PX;
}

.trl {
    margin: 0 20PX 20PX 20PX !important;
}

.tel img {
    width: 17.3PX;
    height: 27.7PX;
}

.telnumber {
    font-size: 13PX;
    font-weight: 400;
    color: #333333;
    margin-bottom: 20PX;
    flex-shrink: 0;
}

.tel .asterisk {
    font-size: 25PX;
    font-weight: 400;
    color: #E6101C;
    margin-left: 5PX;
    margin-right: 59PX;
}

.tel input {
    width: 312PX;
    height: 43PX;
    background: #FFFFFF;
    border: 1PX solid #164B92;
    border-radius: 7PX;
    padding-left: 10PX;
    font-size: 18PX;
    margin-left: 37PX;
}

.tel ::v-deep .el-input__inner {
    width: 312PX;
    background: #FFFFFF;
    border: 1PX solid #164B92;
    border-radius: 7PX;
    font-size: 18PX;
    color: #333333;
}

.tel ::v-deep input::-webkit-input-placeholder {
    color: #333333;
}

.tel ::v-deep input::-moz-input-placeholder {
    color: #333333;
}

.tel ::v-deep input::-ms-input-placeholder {
    color: #333333;
}

.tel ::v-deep .el-select .el-input .el-select__caret {
    color: #191919;
}

.upload-box {
    display: flex;
    flex-direction: column;
}

.upload {
    display: flex;
}

.upload ::v-deep .el-upload-dragger {
    width: 136PX;
    height: 81PX;
    border: 1PX dashed #2368E1;
}

.example {
    // display: flex;
}

.example span {
    font-size: 13PX !important;
    font-weight: 500;
    color: rgba(10, 109, 243, 0.58) !important;
    margin-left: 40PX;
    margin-top: 0 !important;
}

.example img {
    width: 122PX;
    height: 95PX;
    border-radius: 16PX;
    margin-left: 41PX;
    margin-top: 20PX;
}

.upload-box p {
    font-size: 15PX;
    font-weight: 400;
    color: #333333;
    margin-top: 40PX;

}

.line {
    background: #fff;
    height: 4PX;
    margin-top: 31PX;
}

::v-deep .el-radio__label {
    display: none;
}

::v-deep .el-radio__inner {
    width: 19PX;
    height: 19PX;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #0A6DF3;
    background: #0A6DF3;
}

.el-radio {
    margin-right: 5PX !important;
}

.radio {
    display: flex;
    align-items: center;
    margin: 39PX;
}

.radio .sp {
    color: #E6101C;
    margin-right: 8PX;
    font-size: 20PX;
}


.radio p {
    font-size: 12PX;
    font-weight: 400;
    color: #333333;
}

.button {
    display: flex;
    justify-content: center;

    .save {
        width: 100%;
        height: 45PX;
        background-image: linear-gradient(to bottom, #6FB2FF, #095FFF);
        font-size: 15PX;
        border-radius: 50PX;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 35PX 0;
    }
}


.button2 {
    display: flex;
    justify-content: center;
    margin: 30PX 0;

    .save {
        width: 100%;
        height: 45PX;
        background-image: linear-gradient(to bottom, #D8E2EB, #C2D0E6);
        font-size: 15PX;
        border-radius: 50PX;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 35PX 0;
    }
}



.submitt {
    width: 320.2PX;
    height: 67PX;
    background: #A2BAD5;
    margin-left: 67PX;
    font-size: 24PX;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.el-select-dropdown__item {
    font-size: 15PX;
}

::v-deep .el-input__icon {
    display: none;
}

::v-deep .el-input--prefix .el-input__inner {
    padding-left: 15PX;
}

.inputbox {
    display: flex;
    align-items: center;
    height: 41PX;
    margin: 24PX 35PX 0;
    background: #F1F6FF;
    border-radius: 50PX;

    img {
        width: 10PX;
        height: 16PX;
        margin-left: 16PX;
    }

    p {
        font-size: 12PX;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 19PX;
        flex-shrink: 0;
        margin-left: 9PX;
    }

    span {
        font-size: 13PX;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #E90709;
        flex-shrink: 0;
        margin-left: 4PX;
    }

    ::v-deep .el-input__inner {
        border: none;
        background: #F1F6FF;
        border-radius: 50PX;
    }

    .inputboxbut {
        flex-shrink: 0;
        margin-right: 11PX;
        padding: 5PX 10PX;
        font-size: 12PX;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 19PX;
        background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
        border-radius: 50PX;
    }

    ::v-deep .el-select .el-input,
    ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    ::v-deep input::-webkit-input-placeholder {
        color: #333333;
    }
}

.briefintroduction {
    color: #8b8c90;
    font-size: 13PX;
    justify-content: center;
    margin: 20PX 47PX 0 47PX;
}

::v-deep .ant-select-selection--single {
    width: 180PX;
}

::v-deep .ant-select-arrow {
    display: none !important;
}
</style>