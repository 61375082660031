<template>
  <div>
    <H5header :type="2"></H5header>
    <div style="background: #f8fbff; min-height: calc(100vh - 120px)">
      <div style="padding-top: 25px; margin: 0 25px">
        <div class="tnav">
          <div class="navname" @click="getshut">
            <img src="../../../assets/image/44.png" alt="" />
            <div class="name">个人主页</div>
          </div>
          <div class="nav" @click="getopen">
            <div class="name">更多选项</div>
            <img src="../../../assets/image/triangle3.png" alt="" />
          </div>
        </div>
        <div class="overlay" @click="getshuts" v-if="masking"></div>
        <div class="zou" v-if="masking">
          <div class="group_36 flex-row justify-between">
            <img
              class="thumbnail_2"
              referrerpolicy="no-referrer"
              src="../../../assets/image/11.png"
            />
            <span class="text_9">我是裁判员</span>
          </div>
          <div class="group_37 flex-row justify-between">
            <div class="block_2 flex-col"></div>
            <span
              class="text_10"
              @click="menuClick(1)"
              :style="{ color: menuIndex == 1 ? '#408ed6' : '#000000' }"
              >年度注册</span
            >
          </div>
          <div class="group_37 flex-row justify-between">
            <div class="block_2 flex-col"></div>
            <span
              class="text_10"
              @click="menuClick(2)"
              :style="{ color: menuIndex == 2 ? '#408ed6' : '#000000' }"
              >执裁记录</span
            >
          </div>
          <div class="image-text_33 flex-row justify-between">
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="../../../assets/image/22.png"
            />
            <span class="text-group_1">我是运动员</span>
          </div>
          <div class="group_37 flex-row justify-between">
            <div class="block_2 flex-col"></div>
            <span
              class="text_10"
              @click="menuClick(3)"
              :style="{ color: menuIndex == 3 ? '#408ed6' : '#000000' }"
              >我的报名</span
            >
          </div>
          <div class="group_37 flex-row justify-between">
            <div class="block_2 flex-col"></div>
            <span
              class="text_10"
              @click="menuClick(6)"
              :style="{ color: menuIndex == 6 ? '#408ed6' : '#000000' }"
              >赛事成绩</span
            >
          </div>
          <div class="image-text_34 flex-row justify-between">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="../../../assets/image/33.png"
            />
            <span class="text-group_2">安全中心</span>
          </div>
          <div class="group_37 flex-row justify-between">
            <div class="block_2 flex-col"></div>
            <span
              class="text_10"
              @click="menuClick(4)"
              :style="{ color: menuIndex == 4 ? '#408ed6' : '#000000' }"
              >身份验证</span
            >
          </div>
          <div class="group_37 flex-row justify-between">
            <div class="block_2 flex-col"></div>
            <span
              class="text_10"
              @click="menuClick(7)"
              :style="{ color: menuIndex == 7 ? '#408ed6' : '#000000' }"
              >照片授权</span
            >
          </div>
          <div class="group_37 flex-row justify-between">
            <div class="block_2 flex-col"></div>
            <span
              class="text_10"
              @click="menuClick(5)"
              :style="{ color: menuIndex == 5 ? '#408ed6' : '#000000' }"
              >登录设置</span
            >
          </div>
        </div>

        <div class="grxx" v-if="menuIndex == 0">
          <div class="box-logo">
            <img :src="src" alt="" />
          </div>
          <div class="box-title">
            <div class="name">
              <div class="xm-box">
                <img src="../../../assets/image/user.png" alt="" />
                <div class="xm">姓名：</div>
              </div>
              <div
                v-if="
                  this.$store.state.realnameornot.requeststatus == 1 ||
                  this.$store.state.realnameornot.caStatus == 1
                "
              >
                <div class="xm" v-if="realnameLength">
                  {{ this.date.user.realname }}
                </div>
                <div
                  class="xm xms"
                  v-else
                  @click="getcertificate(date.user.realname, '姓名')"
                  style="color: #2a7aff"
                >
                  {{ this.date.user.realname }}
                </div>
              </div>
              <div v-else class="xm"></div>
            </div>
            <div class="name name2">
              <div class="xm-box">
                <img src="../../../assets/image/gender.png" alt="" />
                <div class="xm">性别：</div>
              </div>
              <div
                class="xm"
                v-if="
                  this.$store.state.realnameornot.requeststatus == 1 ||
                  this.$store.state.realnameornot.caStatus == 1
                "
              >
                {{
                  this.date.user.sex == 1
                    ? '男'
                    : this.date.user.sex == 2
                    ? '女'
                    : ''
                }}
              </div>
              <div v-else></div>
            </div>
            <el-upload
              ref="upload"
              :drag="true"
              :limit="1"
              :data="upData"
              :action="uploadUrl"
              :on-success="success"
              :headers="headers"
              :multiple="false"
              :file-list="uploadfiles"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              accept="image/jpg,image/jpeg,image/png"
            >
              <div class="buton">修改头像</div>
            </el-upload>
          </div>
        </div>

        <div class="navli" v-if="menuIndex == 0">
          <div @click="getnav(1)" :class="navIndex == 1 ? 'select' : ''">
            裁判员
          </div>
          <div @click="getnav(2)" :class="navIndex == 2 ? 'select' : ''">
            运动员
          </div>
          <!-- <div @click="getnav(3)" :class="navIndex == 3 ? 'select' : ''">教练员</div> -->
        </div>

        <div v-if="navIndex == 1 && menuIndex == 0">
          <div class="hd" v-if="date.referee.certificateList.length > 0">
            <div class="zuo" @click="getzuo">
              <img src="../../../assets/image/zuo.png" alt="" />
            </div>
            <div class="xx" ref="xxContainer">
              <div
                class="diyc-box"
                v-for="(item, index) in this.date.referee.certificateList"
                :key="index"
                v-if="index >= currentIndex && index < currentIndex + 1"
              >
                <div class="diyc">
                  <div class="diyczuo">
                    <img src="../../../assets/image/logo.png" alt="" />
                    <div class="name">{{ item[0].sportName }}</div>
                  </div>
                  <div class="diycyou">{{ item[0].referee_level }}</div>
                </div>
                <div class="diycxian"></div>
                <div
                  class="diyctitle"
                  @click="getcertificate(item[0].certificate_unit, '发证单位')"
                  style="display: flex"
                >
                  发证单位：
                  <span
                    style="
                      color: #2a7aff;
                      width: 56%;
                      display: block;
                      overflow: hidden;
                      white-space: nowrap;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    >{{ item[0].certificate_unit }}</span
                  >
                </div>
                <div class="diyctitle">
                  发证日期：{{ item[0].certificate_date | format }}
                </div>
                <div class="diyctitle">
                  注册所在区：{{ item[0].certificate_area }}
                </div>
                <div class="diyctitle">
                  注册状态：
                  {{
                    item[0].apply_for_status == 40
                      ? '已注册'
                      : item[0].apply_for_status == 60
                      ? '未年度注册 '
                      : item[0].apply_for_status
                  }}
                </div>
              </div>
            </div>

            <div class="you" @click="getyou">
              <img src="../../../assets/image/you.png" alt="" />
            </div>
          </div>

          <div class="navt" style="width: auto">
            <div class="navtxian"></div>
            <div class="navtname">执裁记录</div>
          </div>

          <div
            class="arbitration-box"
            v-for="(item, index) in this.date.referee.enforcementList"
            :key="index"
          >
            <div>
              <div class="arbitration" @click="getarbitration(item[0])">
                <div class="arbitrationname">
                  {{ item[0].match_sport }}
                </div>
                <div
                  :class="
                    item[0].arbitration ? 'triangle-down' : 'triangle-right'
                  "
                ></div>
              </div>
              <div class="timeline" v-if="item[0].arbitration">
                <el-timeline>
                  <el-timeline-item
                    :timestamp="res.match_date"
                    color="#0063FF"
                    v-for="(res, indexs) in item"
                    :key="indexs"
                  >
                    {{ res.match_name }}
                  </el-timeline-item>
                </el-timeline>
              </div>
            </div>
          </div>

          <div
            v-if="this.$verification(this.date.referee.enforcementList)"
            class="zx-box"
            style="margin-top: 0"
          >
            <img class="zw" src="../../../assets/image/333.png" alt="" />
            <div class="zwxx">暂无信息</div>
          </div>

          <div class="navt" style="margin-top: 0">
            <div class="navtxian"></div>
            <div class="navtname">晋升记录</div>
          </div>

          <div
            class="arbitration-box promotion"
            v-for="(item, index) in this.date.referee.promotionList"
            :key="index"
          >
            <div>
              <div class="arbitration" @click="getarbitration(item[0])">
                <div class="arbitrationname">
                  {{ item[0].sportsName }}
                </div>
                <div
                  :class="
                    item[0].arbitration ? 'triangle-down' : 'triangle-right'
                  "
                ></div>
              </div>
              <div
                class="timelines"
                v-if="item[0].arbitration"
                v-for="(res, indexs) in item"
                :key="indexs"
              >
                <div>
                  <div class="jtxx-box">
                    <div class="jtxxy">
                      <div class="jb">
                        <img src="../../../assets/image/111.png" alt="" />
                        <div style="flex-shrink: 0">晋升级别：</div>
                        <div>{{ res.refereeLevel }}</div>
                      </div>
                      <div class="rq">
                        <img src="../../../assets/image/222.png" alt="" />
                        <div style="flex-shrink: 0">发证日期：</div>
                        <div>{{ res.certificateDate | format }}</div>
                      </div>
                    </div>
                    <div class="dz">
                      <img
                        src="../../../assets/image/99.png"
                        alt=""
                        style="margin-right: 10px"
                      />
                      <div style="flex-shrink: 0">发证单位：</div>
                      <div>{{ res.certificateUnit }}</div>
                    </div>
                  </div>
                  <div class="jtxxxian" v-if="indexs != item.length - 1"></div>
                  <div class="jtxxkb" v-if="indexs == item.length - 1"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="this.$verification(this.date.referee.promotionList)"
            class="zx-box"
            style="margin-top: 0"
          >
            <img class="zw" src="../../../assets/image/333.png" alt="" />
            <div class="zwxx">暂无信息</div>
          </div>
        </div>

        <div v-if="navIndex == 2 && menuIndex == 0">
          <div
            class="zx-box"
            v-if="Object.keys(this.date.sportsman).length === 0"
          >
            <img class="zw" src="../../../assets/image/333.png" alt="" />
            <div class="zwxx">暂无信息</div>
          </div>
          <div class="ydy-box" style="width: auto" v-else>
            <div
              class="ydy ydys"
              v-for="(item, index) in this.date.sportsman.sportsList"
              :key="index"
            >
              <div class="name-box">
                <div class="ydyxian"></div>
                <div class="name">{{ item }}</div>
              </div>
              <div class="ydybut">
                <div class="ydybutname" @click="getentry(item)">参赛记录</div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="navIndex == 3 && menuIndex == 0" class="zx-box">
          <img class="zw" src="../../../assets/image/333.png" alt="" />
          <div class="zwxx">暂无信息</div>
        </div>
      </div>

      <div v-if="menuIndex == 1 && newly">
        <div class="cpzs" @click="newlyadded(2)">
          <p>新增裁判员证书</p>
        </div>

        <div v-loading="loading">
          <div v-for="(item, index) in tableData2" :key="index" class="tab_box">
            <div class="tab_title">
              <span>姓名</span>
              <span>{{ item.refereeName }}</span>
            </div>
            <div class="tab_title">
              <span>项目</span>
              <span>{{ item.sportsName }}</span>
            </div>
            <div class="tab_title">
              <span>等级</span>
              <span>{{ item.referee_Level }}</span>
            </div>
            <div class="tab_title">
              <span>状态</span>
              <span>
                <span v-if="item.applyForStatus == 10">草稿</span>
                <span v-if="item.applyForStatus == 20">待审批</span>
                <span v-if="item.applyForStatus == 30">审核未通过</span>
                <span v-if="item.applyForStatus == 40">已公示</span>
                <span v-if="item.applyForStatus == 60">已过期</span>
              </span>
            </div>
            <div class="tab_title">
              <span style="flex-shrink: 0">操作</span>
              <span
                class="tab_title_name"
                v-if="
                  $store.state.realnameornot.requeststatus == 1 ||
                  $store.state.realnameornot.caStatus == 1
                "
              >
                <span @click="newlyadded(3, item)">查看</span>
                <span
                  @click="newlyadded(5, item)"
                  v-if="item.applyForStatus == 10"
                  >编辑</span
                >

                <span
                  @click="newlyadded(7, item)"
                  v-if="item.applyForStatus == 30"
                  >编辑</span
                >
                <span
                  @click="newlyadded(4, item)"
                  v-if="item.applyForStatus == 40"
                  >申请修改</span
                >
                <span
                  @click="newlyadded(6, item)"
                  v-if="item.applyForStatus == 60"
                  >年度注册</span
                >

                <span @click="getclick(item)" v-if="item.applyForStatus == 30"
                  >查看原因</span
                >
              </span>
              <span class="tab_title_name" v-else>
                <span @click="newlyadded(3, item)">查看</span>
              </span>
            </div>
          </div>
        </div>

        <div class="paging">
          <pagination
            :total="total2"
            :type="2"
            @currentchange="currentchange2"
            :go="10"
            :pageSize="10"
          >
          </pagination>
        </div>
      </div>

      <div v-if="menuIndex == 1 && !newly">
        <refereedetails
          @toexamine="toexamine"
          :step="newlyindex"
          :id="newlyid"
        ></refereedetails>
      </div>

      <div v-if="menuIndex == 2">
        <div class="nian" style="margin-top: 37px">
          <div style="display: flex; align-items: center">
            <img src="../../../assets/image/Event.png" alt="" />
            <p>项目</p>
          </div>
          <div class="select">
            <!-- <el-select v-model="info.matchSport" placeholder="请选择" filterable clearable>
                            <el-option v-for="item in options1" :key="item.itemValue" :label="item.itemText"
                                :value="item.itemValue">
                            </el-option>
                        </el-select> -->

            <a-select
              option-label-prop="label"
              placeholder="请选择"
              v-model="info.matchSport"
              allowClear
            >
              <a-select-option
                v-for="item in options1"
                :key="item.itemValue"
                :value="item.itemValue"
                :label="item.itemText"
              >
                <a-icon
                  type="check"
                  v-if="item.itemValue === info.matchSport"
                />
                {{ item.itemText }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="xian2"></div>
        <div class="nian">
          <div style="display: flex; align-items: center">
            <img src="../../../assets/image/group.png" alt="" />
            <p>赛事名称</p>
          </div>
          <el-input
            placeholder="输入赛事名称"
            v-model="info.matchName"
            style="margin-left: 40px; width: 180px"
          ></el-input>
        </div>
        <div class="xian2"></div>
        <div class="but-box">
          <div class="but2" @click="searchfor">
            <div class="text">搜索</div>
          </div>
          <div class="but2" @click="resetting">
            <div class="text">重置</div>
          </div>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            v-loading="loading"
            border
            style="width: 100%"
            :header-cell-style="{ background: '#0A6DF3' }"
            :default-sort="{ prop: 'date', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="78"
              sortable
            >
            </el-table-column>
            <el-table-column prop="matchName" label="赛事" align="center">
            </el-table-column>
            <el-table-column
              prop="matchSport_dictText"
              label="项目"
              align="center"
            >
            </el-table-column>
            <el-table-column label="赛事时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.matchDateStart }} - {{ scope.row.matchDateEnd }}
              </template>
            </el-table-column>
            <el-table-column
              prop="signStatus_dictText"
              label="状态"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>

        <div class="paging">
          <pagination
            :total="total"
            :refreshNum="refreshNum"
            :type="2"
            @currentchange="currentchange"
            :go="10"
            :pageSize="10"
          >
          </pagination>
        </div>
      </div>

      <div v-if="menuIndex == 3">
        <match></match>
      </div>

      <div v-if="menuIndex == 4">
        <personalInformation
          v-if="nameornot && menuIndex == 4"
          @recertification="recertification"
        >
        </personalInformation>
        <personalAuthentication
          :edit="edit"
          v-if="!nameornot && menuIndex == 4"
          @toexamine="recertification1"
          @cancellation="recertification1"
          :distinguish="distinguish"
        >
        </personalAuthentication>
      </div>

      <div v-if="menuIndex == 5">
        <div v-if="!telshow && !passshow">
          <div class="inputbox">
            <img src="../../../assets/image/tel.png" alt="" />
            <p>手机号</p>
            <span>*</span>
            <el-input v-model="userdate.phone" readonly></el-input>
            <div class="inputboxbut" @click="modifytel">修改</div>
          </div>
          <div class="inputbox">
            <img
              src="../../../assets/image/password.png"
              alt=""
              style="width: 12px; height: 14px"
            />
            <p>登录密码</p>
            <span>*</span>
            <el-input readonly v-model="pas"></el-input>
            <div class="inputboxbut" @click="modifypass">修改</div>
          </div>
        </div>

        <div v-if="telshow">
          <div class="inputbox">
            <img src="../../../assets/image/tel.png" alt="" />
            <p>旧手机号</p>
            <span>*</span>
            <el-input v-model="modifyphone.oldPhone" readonly></el-input>
          </div>
          <div class="inputbox">
            <img src="../../../assets/image/tel.png" alt="" />
            <p>新手机号</p>
            <span>*</span>
            <el-input v-model="modifyphone.newPhone"></el-input>
            <div
              class="inputboxbut"
              @click="startCountdown"
              :disabled="isCountingDown"
            >
              {{ buttonText }}
            </div>
          </div>
          <div class="inputbox">
            <img src="../../../assets/image/tel.png" alt="" />
            <p>验证码</p>
            <span>*</span>
            <el-input v-model="modifyphone.phoneCode"></el-input>
          </div>
          <div class="but" @click="getiphone">提交</div>
        </div>

        <div v-if="passshow">
          <div class="inputbox">
            <img src="../../../assets/image/tel.png" alt="" />
            <p>旧密码</p>
            <span>*</span>
            <el-input
              v-model="changepassword.oldPassword"
              show-password
            ></el-input>
          </div>
          <div class="inputbox">
            <img src="../../../assets/image/tel.png" alt="" />
            <p>新密码</p>
            <span>*</span>
            <el-input
              v-model="changepassword.newPassword"
              show-password
            ></el-input>
          </div>
          <div class="inputbox">
            <img src="../../../assets/image/tel.png" alt="" />
            <p>确认密码</p>
            <span>*</span>
            <el-input v-model="changepassword.confirm" show-password></el-input>
          </div>
          <div class="but" @click="getchangepassword">提交</div>
        </div>
      </div>

      <div v-if="menuIndex == 6">
        <sport></sport>
      </div>

      <personalphotoauthorization
        v-if="menuIndex == 7 && photoau"
        @uploadagain="uploadagain"
      >
      </personalphotoauthorization>

      <photoauthorization
        v-if="menuIndex == 7 && !photoau"
        @photoautoexamine="photoautoexamine"
      >
      </photoauthorization>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
      top="5vh"
    >
      <table
        border="1"
        class="table-xj"
        v-for="(item, index) in tableData3"
        :key="index"
      >
        <tr>
          <td class="dialogtitle">项目</td>
          <td class="content">{{ item.sports }}</td>
        </tr>
        <tr>
          <td class="dialogtitle">赛事名称</td>
          <td class="content">{{ item.matchName }}</td>
        </tr>
        <tr>
          <td class="dialogtitle">赛事时间</td>
          <td class="content">
            {{ item.matchDateStart }} 至 {{ item.matchDateEnd }}
          </td>
        </tr>
        <tr>
          <td class="dialogtitle">地点</td>
          <td class="content">{{ item.matchAddressStadium }}</td>
        </tr>
        <tr>
          <td class="dialogtitle">组别</td>
          <td class="content">{{ item.gameGroup }}</td>
        </tr>
        <tr>
          <td class="dialogtitle">小项</td>
          <td class="content">{{ item.event }}</td>
        </tr>
        <tr>
          <td class="dialogtitle">赛次（轮次）</td>
          <td class="content">{{ item.games }}</td>
        </tr>
        <tr>
          <td class="dialogtitle">代表队</td>
          <td class="content">{{ item.delegation }}</td>
        </tr>
        <tr>
          <td class="dialogtitle">姓名</td>
          <td class="content">{{ item.names }}</td>
        </tr>
        <tr>
          <td class="dialogtitle">成绩</td>
          <td class="content">{{ item.grade }}</td>
        </tr>
        <tr>
          <td class="dialogtitle">名次</td>
          <td class="content">{{ item.ranking }}</td>
        </tr>
        <tr>
          <td class="dialogtitle">备注</td>
          <td class="content">{{ item.remarks }}</td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>

<script>
import H5header from "../components/header2.vue"
import personalAuthentication from './personal-authentication.vue'
import personalInformation from './personal-Information.vue'
import refereedetails from './referee-details.vue'
import sport from './sport.vue'
import match from './match.vue'
import photoauthorization from './photoauthorization.vue'
import personalphotoauthorization from './personal-photoauthorization.vue'
export default {
  components: {
    H5header,
    personalAuthentication,
    personalInformation,
    refereedetails,
    sport,
    match,
    photoauthorization,
    personalphotoauthorization
  },
  data() {
    return {
      date: {
        referee: {
          certificateList: [],
          enforcementList: [],
          promotionList: []
        },
        user: {},
        sportsman: {}
      },
      currentIndex: 0,
      navIndex: 1,
      dialogVisible: false,
      tableData3: [],
      superiorindex: 1,
      masking: false,
      menuIndex: 0,
      src: require('@/assets/image/default.png'),
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/upload',
      upData: {
        fileExtensionList: ["jpg", "png", "jpeg"],
      },
      fileList1: [],
      uploadfiles: [],
      headers: {
        "token": this.$store.state.token
      },

      userdate: {},
      pas: '******',
      modifyphone: {
        userId: '',
        oldPhone: '',
        newPhone: '',
        phoneCode: '',
        userType: ''
      },
      changepassword: {
        userId: '',
        oldPassword: '',
        newPassword: '',
        confirm: '',
        userType: ''
      },
      telshow: false,
      passshow: false,
      nameornot: false,
      edit: false,
      isCountingDown: false,
      qufen: false,
      info: {
        matchName: '',
        matchSport: undefined,
        userId: '',
        pageNo: 1,
        pageSize: 10
      },
      options1: [],
      refereeinfo: {
        userId: null,
        pageNo: 1,
        pageSize: 10,
        refereeleve: '',
        certificateArea: '',
        sports: '',
        refereeName: '',
      },
      total2: 0,
      total: 0,
      tableData: [],
      tableData2: [],
      newly: true,
      newlyindex: '',
      newlyid: {},
      options2: [],
      options3: [],
      remainingSeconds: '',
      distinguish: 0,
      isCountingDown2: false,
      loading: false,
      refreshNum: 1,

      photoau: false,
      realnameLength: true,
    }
  },
  computed: {
    buttonText() {
      if (this.isCountingDown) {
        return `${this.remainingSeconds} 秒后重新获取`;
      } else {
        return '获取验证码';
      }
    },
  },
  filters: {
    format(x) {
      if (x != null && x != '') {
        let time = x.split(" ")[0]
        let parts = time.split('-');
        let result = parts[0] + '-' + parts[1];
        return result
      }
    }
  },
  created() {
    this.menuIndex = this.$route.query.menuIndex || 0,
      this.nameornot = (this.$route.query.nameornot == 'false' ? false : true) || false,
      this.newlyindex = this.$route.query.newlyindex || '2',
      this.newly = this.$route.query.newly == 'false' ? false : true
    this.photoau = this.$route.query.photoau == 'false' ? false : true
    this.judgmentLevel()
    this.areaList()
    this.userInfo()
    this.sportEventList()
    this.getDispatchMatchByRefereeId()
    this.getRefereeInfoList()
    this.getUserCardInfo()
    this.$store.commit("EXIT_REALN")

    this.$getwxsdk('个人中心 -北京市体育竞赛管理和国际交流中心')
  },
  methods: {
    judgmentLevel() {
      this.$api.judgmentLevel().then(res => {
        this.options2 = res.data.data.result
      })
    },
    areaList() {
      this.$api.areaList().then(res => {
        this.options3 = res.data.data.result
      })
    },
    userInfo() {
      let date = {
        phone: this.$store.state.logininformation.phone,
        username: this.$store.state.logininformation.username,
        realname: this.$store.state.logininformation.realname
      }
      this.$api.userInfo(date).then(res => {
        res.data.data.phone = this.$decrypt(res.data.data.phone)
        res.data.data.realname = this.$decrypt(res.data.data.realname)
        res.data.data.username = this.$decrypt(res.data.data.username)
        res.data.data.phone = res.data.data.phone
        res.data.data.realname = res.data.data.realname
        this.userdate = res.data.data
      })
    },
    startCountdown() {
      if (this.modifyphone.newPhone == '') {
        return this.$message.error('请输入新手机号')
      }
      const regex = /^1[3456789]\d{9}$/
      if (!regex.test(this.modifyphone.newPhone)) {
        return this.$message.error('手机号码格式错误')
      }
      if (this.isCountingDown2) {
        return;
      }
      if (this.isCountingDown) {
        return;
      }
      let data = {
        phone: this.modifyphone.newPhone,
        timeOut: 60,
        smsmode: 2 //忘记密码
      }
      this.isCountingDown2 = true
      this.$api.getPhoneCode(data).then(res => {
        this.isCountingDown = true;
        this.remainingSeconds = 60;

        this.countdownTimer = setInterval(() => {
          this.remainingSeconds--;

          if (this.remainingSeconds <= 0) {
            this.stopCountdown();
          }
        }, 1000);
      }).catch(error => {
        this.isCountingDown2 = false;
        console.error('Failed to request verification code:', error);
      })
    },
    stopCountdown() {
      clearInterval(this.countdownTimer);
      this.isCountingDown = false;
      this.remainingSeconds = 0;
      this.isCountingDown2 = false;
    },
    modifytel() {
      this.telshow = true
      this.passshow = false
      this.modifyphone.oldPhone = this.userdate.phone
      this.modifyphone.userId = this.$store.state.userid
    },
    modifypass() {
      this.passshow = true
      this.telshow = false
      this.changepassword.userId = this.$store.state.userid
    },
    getiphone() {
      this.modifyphone.userType = this.$store.state.userType
      if (this.modifyphone.newPhone == '') {
        return this.$message.error('请输入新手机号')
      }
      const regex = /^1[3456789]\d{9}$/
      if (!regex.test(this.modifyphone.newPhone)) {
        return this.$message.error('新手机号格式错误')
      }
      if (this.modifyphone.phoneCode == '') {
        return this.$message.error('请输入验证码')
      }

      let encryptedInfo = {
        userId: this.$encrypt(this.modifyphone.userId),
        oldPhone: this.$encrypt(this.modifyphone.oldPhone),
        newPhone: this.$encrypt(this.modifyphone.newPhone),
        phoneCode: this.$encrypt(this.modifyphone.phoneCode),
        userType: this.$encrypt(this.modifyphone.userType),
      }

      this.$api.updatePhone(encryptedInfo).then(res => {
        this.$message.success('修改成功')

        this.$api.checkLogout({
          accessToken: this.$store.state.token
        }).then(res => {
          setTimeout(() => {
            this.$store.commit("EXIT_TOKEN")
            this.$router.push({
              path: '/h5/login'
            })
          }, 500)
        })

        this.telshow = false
        this.modifyphone.newPhone = '',
          this.modifyphone.phoneCode = ''
        this.userInfo()
      })
    },
    getchangepassword() {
      this.changepassword.userType = this.$store.state.userType
      const pas = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*_-]).{8,16}$/
      if (this.changepassword.oldPassword == '') {
        return this.$message.error('请输入旧密码')
      }
      if (this.changepassword.newPassword == '') {
        return this.$message.error('请输入新密码')
      }
      if (!pas.test(this.changepassword.newPassword)) {
        return this.$message.error('新密码长度 8-16位字符，需包括字母、数字、特殊符号(特殊符号为!@#$%^&*)')
      }
      if (this.changepassword.confirm == '') {
        return this.$message.error('请输入确认密码')
      }
      if (!pas.test(this.changepassword.confirm)) {
        return this.$message.error('密码长度 8-16位字符，需包括字母、数字、特殊符号(特殊符号为!@#$%^&*)')
      }
      if (this.changepassword.newPassword != this.changepassword.confirm) {
        return this.$message.error('新密码与确认密码输入错误，不一致')
      }
      let encryptedInfo = {
        userId: this.$encrypt(this.changepassword.userId),
        oldPassword: this.$encrypt(this.changepassword.oldPassword),
        newPassword: this.$encrypt(this.changepassword.newPassword),
        confirm: this.$encrypt(this.changepassword.confirm),
        userType: this.$encrypt(this.changepassword.userType),
      }

      this.$api.updatePassword(encryptedInfo).then(res => {
        this.$message.success('修改成功')
        this.passshow = false
        this.changepassword.oldPassword = '',
          this.changepassword.newPassword = '',
          this.changepassword.confirm = ''
      })
    },
    recertification() {
      this.nameornot = false
      this.menuIndex = 4
      this.edit = true
      this.qufen = true
      this.distinguish = 1
    },
    recertification1() {
      this.nameornot = true
      this.menuIndex = 4
      this.edit = false
      this.qufen = false
    },
    uploadagain() { // 免冠照审核上传
      this.photoau = false
      this.menuIndex = 7
    },
    photoautoexamine() { // 免冠照提交
      this.photoau = true
      this.menuIndex = 7
    },
    getshut() {
      this.masking = false
      this.menuIndex = 0
      this.navIndex = 1
      this.getUserCardInfo()

      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('photoau');
      urlParams.delete('menuIndex');
      urlParams.delete('newlyindex');
      urlParams.delete('newly');
      const newURL = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, '', newURL); // 更新浏览器地址栏
    },
    getopen() {
      this.masking = true
    },
    getshuts() {
      this.masking = false
    },
    menuClick(key) {
      if (this.$verification(this.$store.state.realnameornot.createTime) && key == 7) {
        return this.$alert('请先完成身份验证并通过审核', '提示', {
          confirmButtonText: '关闭',
          callback: action => { }
        });
      } else if (this.$store.state.realnameornot.caStatus == 0 && key == 7) {
        if (this.$store.state.realnameornot.requeststatus != 1) {
          return this.$alert('请先完成身份验证并通过审核', '提示', {
            confirmButtonText: '关闭',
            callback: action => { }
          });
        }
      } else if (this.$store.state.realnameornot.caStatus == 2 && key == 7) {
        return this.$alert('请先完成身份验证并通过审核', '提示', {
          confirmButtonText: '关闭',
          callback: action => { }
        });
      }

      this.menuIndex = key
      this.$store.commit("EXIT_REALN")
      if (key == 1 || key == 2) {
        this.newly = true
        this.$store.commit("EXIT_REALN")
        this.tableData2 = []
        this.getRefereeInfoList()
      }
      if (key == 5) {
        this.telshow = false
        this.passshow = false
        this.changepassword.oldPassword = '',
          this.changepassword.newPassword = '',
          this.changepassword.confirm = ''
        this.modifyphone.newPhone = '',
          this.modifyphone.phoneCode = ''
      }
      if (key == 4) {
        this.qufen = false
      }

      if (this.$store.state.realnameornot.createTime != null && key == 4) {
        this.nameornot = true
      } else if (this.$store.state.realnameornot.createTime == null && key == 4) {
        this.nameornot = false
      }

      if (this.$verification(this.$store.state.realnameornot.headPic) && key == 7) {
        this.photoau = false
      }
      if (!this.$verification(this.$store.state.realnameornot.headPic) && key == 7) {
        this.photoau = true
      }

      this.masking = false

      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('photoau');
      urlParams.delete('menuIndex');
      urlParams.delete('newlyindex');
      urlParams.delete('newly');
      const newURL = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, '', newURL); // 更新浏览器地址栏
    },

    currentchange(e) {
      this.info.pageNo = e
      this.getDispatchMatchByRefereeId()
    },
    searchfor() {
      this.info.pageNo = 1
      this.refreshNum = 2
      this.getDispatchMatchByRefereeId()
    },
    getDispatchMatchByRefereeId() {
      this.info.userId = this.$store.state.userid
      this.loading = true
      this.$api.getDispatchMatchByRefereeId(this.info).then(res => {
        this.tableData = res.data.data.result.records
        this.total = res.data.data.result.total
        this.loading = false
        this.refreshNum = 1
      }).catch(error => {
        this.loading = false
      })
    },
    resetting() {
      this.info.matchName = ''
      this.info.matchSport = undefined
      this.info.pageNo = 1
      this.info.pageSize = 10
      this.refreshNum = 1
      this.searchfor()
    },
    sportEventList() {
      this.$api.sportEventList({ dictValue: '其他' }).then(res => {
        this.options1 = res.data.data.result
      })
    },
    currentchange2(val) {
      this.refereeinfo.pageNo = val
      this.getRefereeInfoList()
    },
    getRefereeInfoList() {
      this.refereeinfo.userId = this.$store.state.userid || null
      this.tableData2 = []
      this.loading = true
      this.$api.getRefereeInfoList(this.refereeinfo).then(res => {
        this.loading = false
        res.data.data.result.records.forEach(item => {
          for (let i in this.options3) {
            if (item.certificateArea == this.options3[i].itemValue) {
              item.certificate_area = this.options3[i].itemText
            }
          }
          for (let i in this.options2) {
            if (item.refereeLevel == this.options2[i].itemValue) {
              item.referee_Level = this.options2[i].itemText
            }
          }
        })
        res.data.data.result.records.forEach(item => {
          item.refereeName = this.$decrypt(item.refereeName)
          item.certificateUnit = this.$decrypt(item.certificateUnit)
        })
        setTimeout(() => {
          this.tableData2 = res.data.data.result.records
          this.total2 = res.data.data.result.total
        }, 1000)
      }).catch(error => {
        this.loading = false
      })
    },
    newlyadded(index, id) {
      if (this.$verification(this.$store.state.realnameornot.createTime) || this.$store.state.realnameornot.caStatus == 0) { // 未人脸识别
        if (this.$store.state.realnameornot.createTime && this.$store.state.realnameornot.requeststatus == 1) {

          if (index == 6) {
            this.$api.getUserAge({ 'userId': this.$store.state.userid }).then(res => {
              if (res.data.data.result > 65 && id.sports != 'weiqi52') {
                return this.$alert('65周岁以上无需进行裁判员注册工作', '提示', {
                  confirmButtonText: '关闭',
                  callback: action => { }
                });
              } else {
                this.newlyindex = index
                this.newlyid = id
                this.newly = false
              }
            })
          } else {
            this.newlyindex = index
            this.newlyid = id
            this.newly = false
          }
        } else {
          if (!this.$store.state.realnameornot.createTime) {
            this.$confirm('当前账号尚未身份验证，是否前往身份验证', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.menuIndex = 4
              this.nameornot = false
            }).catch(() => { });

          } else if (this.$store.state.realnameornot.requeststatus == 0) {
            this.$alert('当前账号身份验证审核中，请等待审核完成', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
                this.menuIndex = 4
                this.nameornot = true
              }
            });
          }
          else if (this.$store.state.realnameornot.requeststatus == 2) {
            this.$alert('当前账号身份验证未通过，请先完成身份验证待审核 ', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
                this.menuIndex = 4
                this.nameornot = true
              }
            });
          }
        }
      } else { // 通过人脸识别
        if (this.$store.state.realnameornot.caStatus == 2) {
          this.$alert('当前账号人脸识别未通过，请重新认证', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.menuIndex = 1
              this.nameornot = true
            }
          });
        } else if (this.$store.state.realnameornot.requeststatus == 0 && this.$verification(this.$store.state.realnameornot.headPic)) {
          this.$alert('当前账号未上传免冠照，请上传', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.menuIndex = 7
              this.photoau = false
            }
          });
        } else if (this.$store.state.realnameornot.requeststatus == 0 && !this.$verification(this.$store.state.realnameornot.headPic)) {
          this.$alert('当前账号免冠照审核中，请等待审核完成', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.menuIndex = 7
              this.photoau = true
            }
          });
        } else if (this.$store.state.realnameornot.requeststatus == 2) {
          this.$alert('当前账号免冠照审核未通过，请重新上传', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.menuIndex = 7
              this.photoau = true
            }
          });
        } else if (this.$store.state.realnameornot.caStatus == 1 && this.$store.state.realnameornot.requeststatus == 1) {
          if (index == 6) {
            this.$api.getUserAge({ 'userId': this.$store.state.userid }).then(res => {
              if (res.data.data.result > 65 && row.id.sports != 'weiqi52') {
                return this.$alert('65周岁以上无需进行裁判员注册工作', '提示', {
                  confirmButtonText: '关闭',
                  callback: action => { }
                });
              } else {
                this.newlyindex = index
                this.newlyid = id
                this.newly = false
              }
            })
          } else {
            this.newlyindex = index
            this.newlyid = id
            this.newly = false
          }
        }
      }
      window.scrollTo(0, 0)
    },
    toexamine() {
      this.newlyindex = 1
      this.menuIndex = 1
      this.newly = true
      this.getRefereeInfoList()
      window.scrollTo(0, 0)
    },
    getclick(row) {
      this.$alert(row.applyContent, '审批意见', {
        confirmButtonText: '关闭',
        callback: action => { }
      });
    },

    getUserCardInfo() {
      this.$api.getUserCardInfo({ userId: this.$store.state.userid }).then(res => {
        if (!this.$verification(res.data.data.result.user.headPic)) {
          this.$api.getFile({ objectName: res.data.data.result.user.headPic }).then(ess => {
            this.$convert(ess.data.data.result.url).then((fileUrl) => {
              this.src = fileUrl
              this.$forceUpdate()
            })
          })
        }

        res.data.data.result.referee.certificateList.forEach(item => {
          item.forEach(err => {
            err.realName = this.$decrypt(err.realName || '')
            err.certificate_unit = this.$decrypt(err.certificate_unit || '')

            for (let i in this.options2) {
              if (err.referee_level == this.options2[i].itemValue) {
                err.referee_level = this.options2[i].itemText
              }
            }
            for (let i in this.options3) {
              if (err.certificate_area == this.options3[i].itemValue) {
                err.certificate_area = this.options3[i].itemText
              }
            }
          })
        })

        res.data.data.result.referee.enforcementList.forEach(item => {
          item.forEach(res => {
            let match_date = JSON.parse(res.match_date)
            let arr = match_date.map(err => { return err.startDate + ' 至 ' + err.endDate })
            res.match_date = arr.join("，")
            res.arbitration = true
          })
        })

        res.data.data.result.referee.promotionList.forEach(item => {
          item.arbitration = true
          item.forEach(res => {
            res.certificateUnit = this.$decrypt(res.certificateUnit || '')
            res.arbitration = true
          })
        })
        res.data.data.result.user.realname = this.$decrypt(res.data.data.result.user.realname || '')
        res.data.data.result.user.phone = this.$decrypt(res.data.data.result.user.phone || '')
        if (res.data.data.result.user.realname.length > 4) {
          this.realnameLength = false
        } else {
          this.realnameLength = true
        }
        this.date = res.data.data.result
      })
    },
    getnav(e) {
      this.navIndex = e
    },
    getarbitration(e) {
      e.arbitration = !e.arbitration
    },
    getentry(item) {
      this.dialogVisible = true
      let data = JSON.parse(JSON.stringify(this.date.sportsman))
      data.sports = item
      this.$api.getSportsmanScore(data).then(res => {
        res.data.data.result.forEach(item => {
          item.names = this.$decrypt(item.names || '')
          item.matchAddressStadium = item.addresses.map(items => {
            return items.matchAddressStadium
          }).join('、')
        })
        this.tableData3 = res.data.data.result
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    getzuo() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
      }
    },
    getyou() {
      if (this.currentIndex + 1 < this.date.referee.certificateList.length) {
        this.currentIndex += 1;
      }
    },
    beforeAvatarUpload(file) {
      console.log(file.type.lastIndexOf("/"))
      let index = file.type.lastIndexOf("/")
      let ext = file.type.substr(index + 1)
      let formats = ['png', 'jpg', 'jpeg']
      const isLt5M = file.size / 1024 / 1024 < 5

      const isJPG = formats.includes(ext)

      if (!isJPG) {
        this.$message.error('文件格式不符合！仅支持png、jpg、jpeg格式');
      } else if (!isLt5M) {
        this.$message.error('上传文件大小不能超过5M!')
      }
      return isJPG && isLt5M;
    },
    success(response, file, fileList) {
      if (response.code == -1) {
        this.$alert('登录状态已过期，请重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          type: 'warning'
        }).then(async () => {
          //注销去登录页
          this.$store.commit("EXIT_TOKEN")
          if (window.location.href.includes('/h5')) {
            location.href = '/h5/login'
          } else {
            location.href = '/login'
          }
        })
        this.$refs.upload.clearFiles()
        return
      }

      if (response.code != 200) {
        this.$refs.upload.clearFiles()
        return this.$message.error(response.msg)
      }
      if (response.code != 200) {
        this.$refs.upload.clearFiles()
        return this.$message.error(response.msg)
      } else {
        let data = {
          id: this.date.user.id,
          headPic: response.data.message
        }
        this.$api.updateAvatar(data).then(res => {
          this.$message.success('上传成功')
          this.getUserCardInfo()
        })
      }
    },
    getcertificate(e, title) {
      this.$alert(e, title, {
        confirmButtonText: '关闭',
        callback: action => { }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.tnav {
    margin: 0 0 25PX 0;
    height: 40PX;
    background: #0576F0;
    display: flex;
    border-radius: 15PX;
    z-index: 999;
    position: relative;

    .navname {
        width: 50%;
        background: #0576F0;
        border-top-left-radius: 15PX;
        border-bottom-left-radius: 15PX;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 15PX;

        img {
            width: 18.3PX;
            height: 18.8PX;
            margin-left: 25PX;
        }

        .name {
            margin-left: 11PX;
        }
    }

    .nav {
        width: 50%;
        background: #1C5AB8;
        border-top-right-radius: 15PX;
        border-bottom-right-radius: 15PX;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 15PX;
        justify-content: flex-end;

        img {
            width: 20PX;
            height: 10PX;
            margin: 0 25PX 0 20PX;
        }
    }
}

.grxx {
    margin: 10PX 0;
    height: 185PX;
    background: url('../../../assets/image/666.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;

    .box-logo {
        width: 100PX;
        height: 130PX;
        border-radius: 18PX;
        margin-top: 30PX;
        margin-left: 20PX;

        img {
            border-radius: 18PX;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .box-title {
        margin-left: 21PX;
        margin-top: 15PX;
        min-width: 190PX;
        width: calc(100% - 191PX);

        .name {
            display: flex;
            border-bottom: 1PX solid rgba(110, 110, 110, 0.48);
            height: 30PX;
            display: flex;
            justify-content: space-between;
            margin-top: 20PX;

            img {
                width: 16.2PX;
                height: 18.3PX;
                margin-right: 10PX;
            }

            .xm-box {
                display: flex;

                .xm {
                    font-family: SourceHanSansCN;
                    font-weight: 500;
                    font-size: 14PX;
                    color: #000000;
                    margin-right: 15PX;
                    flex-shrink: 0;
                }
            }

            .xm {
                font-family: SourceHanSansCN;
                font-weight: 500;
                font-size: 14PX;
                color: #000000;
                margin-right: 15PX;
            }

            .xms {
                width: 40%;
                white-space: nowrap;
                /* 确保文本在一行内显示 */
                overflow: hidden;
                /* 隐藏超出容器的文本 */
                text-overflow: ellipsis;
                /* 使用省略号表示被截断的文本 */
                text-align: right;
            }
        }

        .name2 {
            margin-top: 25PX;
        }

        .buton {
            width: 100%;
            height: 35PX;
            color: #184AD1;
            border-radius: 5PX;
            font-size: 14PX;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url('../../../assets/image/but.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }

        ::v-deep .el-upload-dragger {
            width: 100%;
            height: 35PX;
            margin-top: 10PX;
            border: none;
            background: none;
        }

        ::v-deep .el-upload {
            width: 100%;
        }
    }
}


.navli {
    display: flex;
    height: 35PX;
    width: calc(100% - 40PX);
    background: #E8F1FF;
    border-radius: 32PX;
    margin: 10PX 20PX 0 20PX;

    div {
        font-family: Microsoft YaHei;
        font-weight: 600;
        font-size: 15PX;
        width: 33.3%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0576F0;
    }

    .select {
        background: #0576F0;
        border-radius: 32PX;
        color: #FFF;
    }
}

.hd {
    margin-top: 20PX;
    display: flex;

    .zuo {
        width: 32PX;
        height: 32PX;
        margin-top: 35PX;
        cursor: pointer;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .you {
        width: 32PX;
        height: 32PX;
        margin-top: 35PX;
        cursor: pointer;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .xx {
        overflow: hidden;
        width: calc(100% - 48PX);

        .diyc-box {
            width: calc(100% - 48PX);
            height: 150PX;
            background: url('../../../assets/image/88.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin: 0 24PX;

            .diyc {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .diyczuo {
                    display: flex;
                    align-items: center;
                    padding-top: 18PX;
                    padding-left: 12PX;

                    img {
                        width: 25PX;
                        height: 22PX;
                    }

                    .name {
                        // font-family: SourceHanSansCN;
                        font-weight: bold;
                        font-size: 15PX;
                        color: #1469FF;
                        margin-left: 10PX;
                    }
                }

                .diycyou {
                    width: 60PX;
                    height: 20PX;
                    background: url('../../../assets/image/77.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    font-family: SourceHanSansCN;
                    font-weight: bold;
                    font-size: 12PX;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    right: 38PX;
                }
            }

            .diycxian {
                width: calc(100% - 38PX);
                height: 1PX;
                background: #7FB5FD;
                border-radius: 0PX;
                margin: 10PX 19PX 10PX 19PX;
            }

            .diyctitle {
                // font-family: SourceHanSansCN;
                font-weight: 500;
                font-size: 14PX;
                color: #5E5E5E;
                margin-bottom: 2PX;
                margin-left: 19PX;
            }
        }
    }
}

.navt {
    width: 100%;
    height: 50PX;
    background: #FFFFFF;
    box-shadow: 5PX 14PX 18PX 3PX rgba(170, 202, 237, 0.16);
    border-radius: 19PX;
    margin: 20PX 0 20PX 0;
    display: flex;
    align-items: center;

    .navtxian {
        width: 8PX;
        height: 20PX;
        background: #2A7AFF;
        border-radius: 5PX;
        margin-left: 38PX;
    }

    .navtname {
        font-family: SourceHanSansCN;
        font-weight: bold;
        font-size: 15PX;
        color: #2A7AFF;
        margin-left: 21PX;
    }
}

.arbitration-box {
    width: 100%;
    margin-bottom: 19PX;

    .arbitration {
        min-width: 85PX;
        height: 30PX;
        background: linear-gradient(150deg, #0D63FF, #3280FF);
        border-radius: 19PX 19PX 19PX 0PX;
        display: inline-block;
        cursor: pointer;
        line-height: 30PX;
        margin-left: 20PX;
        padding: 0 19PX;
    }

    .arbitrationname {
        font-family: SourceHanSansCN;
        font-weight: bold;
        font-size: 15PX;
        color: #FFFFFF;
        margin-right: 8PX;
        display: inline-block;
    }

    .triangle-down {
        width: 0;
        height: 0;
        border-left: 4PX solid transparent;
        /* 左边框为透明，宽度为4PX */
        border-right: 4PX solid transparent;
        /* 右边框为透明，宽度为4PX */
        border-top: 7PX solid #FFFFFF;
        /* 上边框为白色，高度为7PX */
        display: inline-block;
        position: relative;
        top: -2PX;
    }

    .triangle-right {
        width: 0;
        height: 0;
        border-top: 4PX solid transparent;
        /* 上边框为透明，高度为4PX */
        border-bottom: 4PX solid transparent;
        /* 下边框为透明，高度为4PX */
        border-left: 7PX solid #FFFFFF;
        /* 左边框为白色，宽度为7PX */
        display: inline-block;
        position: relative;
        top: -2PX;
    }

    .timeline {
        margin-top: 20PX;
        margin-left: 45PX;
        padding: 0 !important;

        ::v-deep .el-timeline-item__content {
            font-family: SourceHanSansCN;
            font-weight: 500;
            font-size: 16PX;
            color: #000000;
        }

        ::v-deep .el-timeline-item__timestamp {
            font-family: SourceHanSansCN;
            font-weight: 500;
            font-size: 14PX;
            color: #9B9B9B;
        }

        ::v-deep .el-timeline-item__tail {
            border-left: 2PX solid #0063FF !important;
        }

        ::v-deep .el-timeline-item {
            padding-bottom: 20PX !important;
        }
    }
}

.promotion {

    .timelines {
        margin-top: 20PX;

        .jtxx-box {
            margin-left: 35PX;

            .jtxxy {
                display: flex;
                align-items: center;
                font-family: SourceHanSansCN;
                font-weight: 500;
                font-size: 14PX;
                color: #000000;

                .jb {
                    display: flex;
                    align-items: center;

                    img {
                        width: 20.2PX;
                        height: 26.3PX;
                    }

                    div:nth-child(3) {
                        color: #2D7CFF;
                    }
                }

                .rq {
                    display: flex;
                    position: absolute;
                    margin-left: 40%;

                    img {
                        width: 22.1PX;
                        height: 20.5PX;
                    }
                }
            }

            .dz {
                display: flex;
                align-items: center;
                font-family: SourceHanSansCN;
                font-weight: 500;
                font-size: 14PX;
                color: #000000;
                margin-top: 20PX;

                img {
                    width: 26PX;
                    height: 20PX;
                }

            }

            img {
                margin-right: 15PX;
            }
        }

        .jtxxxian {
            width: calc(100% - 52PX);
            height: 1PX;
            background: rgba(211, 211, 211, 0.4);
            border-radius: 0PX;
            margin: 20PX 0 20PX 35PX;
        }
    }
}

.ydy-box {
    margin: 47PX 0 0 0;
    display: flex;
    flex-wrap: wrap;


    .ydy {
        width: 48%;
        height: 130PX;
        background: url('../../../assets/image/555.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: 10PX;
        margin-bottom: 20PX;

        .name-box {
            display: flex;
            align-items: center;
            margin-left: 26PX;
            padding-top: 14PX;

            .ydyxian {
                width: 4PX;
                height: 16PX;
                background: #417BC8;
                border-radius: 3PX;
            }

            .name {
                font-family: SourceHanSansCN;
                font-weight: bold;
                font-size: 15PX;
                color: #408BFF;
                line-height: 36PX;
                background: linear-gradient(150deg, #336EBC 0.146484375%, #81B7FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-left: 10PX;
            }
        }

        .ydybut {
            width: 109PX;
            height: 26PX;
            background: url('../../../assets/image/444.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            margin-top: 33PX;
            margin-left: 25%;
            cursor: pointer;

            .ydybutname {
                font-family: SourceHanSansCN;
                font-weight: 500;
                font-size: 13PX;
                color: #629AE4;
                margin-left: 35PX;
            }
        }
    }

    .ydy:nth-child(2n) {
        margin-right: 0;
    }
}

.zx-box {
    margin-top: 40PX;
    display: flex;
    flex-direction: column;
    align-items: center;

    .zw {
        width: 257PX;
        height: 162PX;
    }

    .zwxx {
        font-family: SourceHanSansCN;
        font-weight: 500;
        font-size: 15PX;
        color: #989898;
        margin-top: 27PX;
        margin-bottom: 40PX;
    }
}

.zou {
    width: 43.5%;
    padding: 20PX 0;
    background: #fff;
    position: absolute;
    right: 25PX;
    margin-top: -37PX;
    z-index: 998;

    .label_1 {
        width: 28PX;
        height: 27PX;
        margin: 19PX 0 0 23PX;
    }


    .text_8 {
        width: 97PX;
        height: 22PX;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 22PX;
        font-family: MicrosoftYaHei;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 36PX;
        margin: 23PX 41PX 0 11PX;
        cursor: pointer;
    }

    .text_88 {
        position: relative;
        top: 13PX;
    }

    .group_36 {
        width: 135PX;
        height: 21PX;
        margin: 10PX 0 0 22PX;
        display: flex;
        align-items: center;
    }

    .thumbnail_2 {
        width: 6PX;
        height: 15PX;
    }

    .text_9 {
        width: 101PX;
        overflow-wrap: break-word;
        color: rgba(22, 75, 146, 1);
        font-size: 17PX;
        font-family: SourceHanSansCN-Bold;
        font-weight: 700;
        text-align: left;
        white-space: nowrap;
        margin-top: 1PX;
        margin-left: 26PX;
        cursor: pointer;
    }

    .group_37 {
        width: 100PX;
        height: 17PX;
        margin: 24PX 0 0 24PX;
        display: flex;
        align-items: center;
    }

    .block_2 {
        background-color: rgba(110, 110, 110, 1);
        border-radius: 50%;
        width: 4PX;
        height: 4PX;
        margin-top: 7PX;
    }

    .text_10 {
        width: 69PX;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 15PX;
        font-family: SourceHanSansCN-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 36PX;
        margin-left: 27PX;
        cursor: pointer;
    }

    .group_38 {
        width: 100PX;
        height: 17PX;
        margin: 24PX 0 0 24PX;
    }

    .section_1 {
        background-color: rgba(110, 110, 110, 1);
        border-radius: 50%;
        width: 4PX;
        height: 4PX;
        margin-top: 9PX;
    }

    .text_11 {
        width: 69PX;
        height: 17PX;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 17PX;
        font-family: SourceHanSansCN-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 36PX;
    }

    .image-text_33 {
        width: 140PX;
        height: 23PX;
        margin: 43PX 0 0 17PX;
        display: flex;
        align-items: center;
    }

    .label_2 {
        width: 20PX;
        height: 18PX;
    }

    .text-group_1 {
        width: 101PX;
        overflow-wrap: break-word;
        color: rgba(22, 75, 146, 1);
        font-size: 19PX;
        font-family: SourceHanSansCN-Bold;
        font-weight: 700;
        text-align: left;
        white-space: nowrap;
        margin-top: 3PX;
        margin-left: 12PX;
    }

    .group_39 {
        width: 98PX;
        height: 17PX;
        margin: 19PX 0 0 24PX;
    }

    .text_12 {
        width: 67PX;
        height: 17PX;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 17PX;
        font-family: SourceHanSansCN-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 36PX;
    }

    .image-text_34 {
        width: 115PX;
        height: 21PX;
        margin: 34PX 0 0 15PX;
        display: flex;
        align-items: center;
    }

    .image_4 {
        width: 16PX;
        height: 16PX;
    }

    .text-group_2 {
        width: 80PX;
        overflow-wrap: break-word;
        color: rgba(22, 75, 146, 1);
        font-size: 19PX;
        font-family: SourceHanSansCN-Bold;
        font-weight: 700;
        text-align: left;
        white-space: nowrap;
        margin-left: 21PX;
    }

    .group_40 {
        width: 100PX;
        height: 17PX;
        margin: 14PX 0 0 24PX;
    }

    .text_13 {
        width: 69PX;
        height: 17PX;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 17PX;
        font-family: SourceHanSansCN-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 36PX;
    }

    .group_41 {
        width: 99PX;
        height: 16PX;
        margin: 20PX 0 1832PX 25PX;
    }

    .text_14 {
        width: 69PX;
        height: 16PX;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 17PX;
        font-family: SourceHanSansCN-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 36PX;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* 这里的最后一个数字表示遮罩的透明度，可以根据需要调整 */
    z-index: 997;
    /* 确保遮罩在最顶层 */
}

.imgbox {
    display: flex;
    justify-content: center;
}

.xiugai {
    width: 90PX;
    height: 73PX;
    margin-top: 36PX;
    display: flex;
    justify-content: center;
}

.butbox {
    height: 30PX;
    background: #E8F1FF;
    border-radius: 15PX;
    margin: 37PX 35PX 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
        font-size: 12PX;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #0576F0;
        line-height: 12PX;
        padding: 9PX 20PX;
    }

    .name1 {
        background: #0576F0;
        border-radius: 15PX;
        color: #fff;
    }
}

.title {
    display: flex;
    margin: 28PX 48PX 0;

    img {
        width: 34PX;
        height: 34PX;
    }

    p {
        font-size: 10PX;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        display: flex;
        flex-direction: column;
        margin-left: 12PX;
        margin-top: 4PX;
    }
}

.inputbox {
    display: flex;
    align-items: center;
    height: 41PX;
    margin: 24PX 35PX 0;
    background: #F1F6FF;
    border-radius: 50PX;

    img {
        width: 10PX;
        height: 16PX;
        margin-left: 16PX;
    }

    p {
        font-size: 12PX;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 19PX;
        flex-shrink: 0;
        margin-left: 9PX;
    }

    span {
        font-size: 13PX;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #E90709;
        flex-shrink: 0;
        margin-left: 4PX;
    }

    ::v-deep .el-input__inner {
        border: none;
        background: #F1F6FF;
        border-radius: 50PX;
        padding: 0 0 0 15PX;
    }

    .inputboxbut {
        flex-shrink: 0;
        margin-right: 11PX;
        padding: 5PX 10PX;
        font-size: 12PX;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 19PX;
        background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
        border-radius: 50PX;
    }
}

.but {
    height: 45PX;
    margin: 27PX 35PX;
    background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
    border-radius: 50PX;
    font-size: 15PX;
    font-family: Microsoft YaHei;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.cpbutbox {
    display: flex;
    justify-content: center;
    margin-top: 21PX;

    .cpbut {
        margin-right: 32PX;
        font-size: 13PX;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;

        .cPXian {
            height: 2PX;
            background: #2C81FF;
            margin-top: 5PX;
        }
    }

    .cpbut:nth-child(2) {
        margin-right: 0;
    }
}

.nian {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 28PX;

    img {
        width: 17PX;
        height: 17PX;
        flex-shrink: 0;
    }

    p {
        font-size: 14PX;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 12PX;
        flex-shrink: 0;
    }

    .select ::v-deep .el-select .el-input .el-input__inner {
        border-color: transparent !important;
        background-color: transparent !important;
        box-shadow: none !important;
        color: #000;
    }

    .select ::v-deep .el-select .el-input,
    .select ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .select ::v-deep .el-select .el-input .el-select__caret.is-reverse {
        transform: rotateZ();
    }

    .select ::v-deep .el-select .el-input .el-select__caret {
        color: #C0C4CC;
        font-size: 14PX;
        transition: transform .3s;
        transform: rotateZ(89deg);
        cursor: pointer;
        left: 15PX;
        position: relative;
    }

    .select ::v-deep input::-webkit-input-placeholder {
        color: #000;
    }

    .select ::v-deep .el-select .el-input .el-select__caret {
        color: #000;
    }

    ::v-deep .el-input .el-input__inner {
        border: none !important;
        padding: 0 12PX;
        color: #000;
        background: #F8FBFF;
    }

    ::v-deep input::-webkit-input-placeholder {
        color: #000;
    }
}

.xian2 {
    margin: 0 28PX;
    height: 1PX;
    background: rgba(194, 194, 194, 0.2);
}

.but-box {
    display: flex;
    justify-content: center;
    margin: 20PX 0;
}

.but2 {
    font-size: 14PX;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #F5F7FB;
    height: 30PX;
    background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
    padding: 20PX 55PX;
    border-radius: 50PX;
    margin-right: 20PX;
    display: flex;
    align-items: center;
}

.but2:nth-child(2) {
    background: linear-gradient(to bottom, #5CDCFE, #2AADF1);
    margin-right: 0;
}

.table {
    margin-top: 40PX;
    margin-left: 23PX;
    margin-right: 23PX;
}

.table ::v-deep .el-table thead {
    color: #fff;
    font-size: 16PX;
    font-weight: 500;
    font-size: 11PX;
}

.table ::v-deep .el-table__header-wrapper {
    border-top-left-radius: 10PX;
    border-top-right-radius: 10PX;
}

.table ::v-deep .el-table {
    border-top-left-radius: 10PX;
    border-top-right-radius: 10PX;
}

.table ::v-deep .el-table th.el-table__cell.is-leaf {
    border-bottom: 1PX solid #333333;
    border-right: 1PX solid #333333;
}

.table ::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
    border-color: #333333;
}

.table ::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
    background-color: #333333;
}

.paging {
    padding: 0 23PX 28PX;
    display: flex;
    align-items: center;
}

.paging span {
    margin-right: 5PX;
}

.cpzs {
    margin: 27PX 29PX;
    width: calc(100% - 58PX);
    height: 40PX;
    background: url('../image/xzcpzs.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;

    p {
        font-size: 14PX;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 25PX;
        margin-top: 8PX;
    }
}

.tab_box {
    margin: 0 45PX 30PX;

    .tab_title {
        padding: 14PX 0;
        border-bottom: 1PX solid #C2C2C2;
        display: flex;

        span {
            font-size: 15PX;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
        }

        span:nth-child(2) {
            margin-left: 75PX;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
        }

        .tab_title_name {
            display: flex;
            flex-wrap: wrap;

            span {
                margin-left: 0;
                margin-right: 20PX;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #006EEF;
                border: 1PX solid #006EEF;
                border-radius: 5PX;
                padding: 5PX 10PX;
                margin-bottom: 10PX;
            }
        }
    }
}

.table-xj {
    width: 100%;
    margin-bottom: 20PX;

    tr {
        height: 30PX;
    }

    .dialogtitle {
        color: #fff;
        background: #0D6DF5;
        width: 90PX;
        text-align: center;
    }

    .content {
        text-align: center;
        padding: 0 5PX;
        color: #333333;
        word-break: break-all;
        overflow-wrap: break-word;
    }
}

::v-deep .el-dialog__body {
    max-height: 85vh;
    overflow: hidden;
    overflow-y: auto;
}
</style>