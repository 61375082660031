<template>
	<div>
		<div class="information-box">
			<img class="user" :src="url" alt="">
			<div class="information ">
				<p class="name"></p>
				<p class="authentication ">免冠照审核：
					<span style="color: #DF2D10;" v-if="this.$store.state.realnameornot.requeststatus == 0">已申请</span>
					<span style="color: #67C23A;" v-if="this.$store.state.realnameornot.requeststatus == 1">已通过</span>
					<span style="color: #DF2D10;" v-if="this.$store.state.realnameornot.requeststatus == 2">认证未通过</span>
					<span style="color: #DF2D10;" v-if="this.$store.state.realnameornot.requeststatus == 3">未认证</span>
				</p>
				<p class="number"></p>
			</div>
		</div>
		<div class="but" @click="recertification">重新上传</div>
        <p class="title">个人免冠照片重新上传后，需1个工作日完成审核。审核期间，官网裁判员注册信息公示及运动员成绩查询等栏目的展示将显示原照片。</p>
		<p class="title">如有技术问题，请拨打以下电话与网站技术联系。</p>
		<p class="title">联系电话：010-63827176，咨询时间：工作日（周一至周五）9:00 - 17:00</p>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				url: ''
			}
		},
		created() {
			this.$api.getFile({objectName:this.$store.state.realnameornot.headPic}).then(res=>{
				if(!this.$verification(this.$store.state.realnameornot.headPic)){
					this.$convert( res.data.data.result.url).then((fileUrl) => {
						this.url = fileUrl
						this.$forceUpdate()
					})
				}else{
					this.url = require('@/assets/image/default.png')
				}
			})
			
			this.$getwxsdk('免冠照审核 -北京市体育竞赛管理和国际交流中心')
		},
		methods: {
			recertification() {
				if(this.$store.state.realnameornot.caStatus == 0){
					if(this.$store.state.realnameornot.requeststatus != 1){
						return this.$alert('请先完成身份验证并通过审核', '提示', {
							confirmButtonText: '关闭',
							callback: action => { }
						});
					}
				}else{
					if(this.$store.state.realnameornot.caStatus != 1){
						return this.$alert('请先完成身份验证并通过审核', '提示', {
							confirmButtonText: '关闭',
							callback: action => { }
						});
					}
				}
				this.$emit('uploadagain')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.information-box {
		display: flex;
	}

	.user {
		width: 75PX;
		height: 84PX;
		margin-top: 37PX;
		margin-left: 47PX;
		border-radius: 10PX;
	}

	.information {
		margin-left: 28PX;
		margin-top: 47PX;
	}

	.name {
		font-size: 13PX;
		font-weight: 800;
		color: #333333;
	}

	.authentication {
		font-size: 13PX;
		font-weight: 500;
		color: #333333;
		margin-top: 10PX;
	}

	.number {
		font-size: 13PX;
		font-weight: 500;
		color: #333333;
		margin-top: 10PX;
	}

	.but {
		margin: 0 28PX;
		height: 45PX;
		background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
		font-size: 15PX;
		font-weight: bold;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 52PX;
		cursor: pointer;
		border-radius: 50PX;
	}

    .title{
		margin: 28PX;
        font-size: 13PX;
	}
</style>